<script>
export default {
  metaInfo() {
    return {
      title: 'Outreach - About - Europe PMC',
    }
  },
  data() {
    return {
      basicLogoRGBLink: require('@/assets/Europe PMC Basic Logo RGB.png'),
      basicLogoCMYKLink: require('@/assets/Europe PMC Basic Logo CMYK.png'),
      extendedLogoRGBLink: require('@/assets/Europe PMC Extended Logo RGB.png'),
      extendedLogoCMYKLink: require('@/assets/Europe PMC Extended Logo CMYK.png'),
      fundersLogoLink: require('@/assets/Europe PMC Funders logo.png'),
    }
  },
}
</script>
<template>
  <div id="outreach-page">
    <div class="grid-row">
      <div class="col-11">
        <h1>Outreach</h1>
        <p>
          Get the word out about Europe PMC! To support your outreach efforts,
          we have created a variety of resources. All materials are CC BY-SA
          licensed and may be reused and customized as required. Further
          materials and publications are available in the Europe PMC
          <a href="//figshare.com/authors/Europe_PMC/3725686">Figshare folder</a
          >. Are you planning a communications campaign, or arranging an event?
          Please contact us if you need help or would like to discuss some
          ideas. You can also help by sharing Europe PMC news that appear on our
          Twitter, blog, and YouTube channel.
        </p>

        <h2>Materials</h2>
        <h3>Slides</h3>
        <p>
          To help you share the benefits of Europe PMC with your colleagues, we
          have created a presentation that covers all the main points. You are
          welcome to create your own presentation or modify this one however you
          want.
        </p>
        <p>
          <a href="/doc/Your gateway to knowledge_Europe PMC.pptx"
            >Your gateway to knowledge: Europe PMC.pptx</a
          >
          (1.4MB)
        </p>
        <div>
          <img
            src="@/assets/Your gateway to knowledge.jpg"
            class="med-img"
            alt="Your gateway to knowledge"
          />
        </div>

        <h3>Posters</h3>
        <p>
          Help spread awareness of Europe PMC and the benefits of open research
          by putting up the Europe PMC poster in your building or at your desk.
          Simply download below and print it off.
        </p>
        <p><a href="/doc/Europe PMC poster.pdf">A4 poster.pdf</a> (183KB)</p>
        <div>
          <img
            src="@/assets/Europe PMC poster.png"
            class="small-img"
            alt="Europe PMC poster"
          />
        </div>

        <h3>Europe PMC brochures</h3>
        <p>
          <a href="/doc/Europe PMC_search_access_discover.pdf"
            >A4 "Europe PMC: search, access, discover".pdf</a
          >
          (8.3MB)
        </p>
        <div>
          <img
            src="@/assets/Europe PMC_search_access_discover.png"
            class="small-img"
            alt="Europe PMC_search_access_discover"
          />
        </div>

        <p>
          <a href="/doc/Lost in scientific literature.pdf"
            >A4 "Lost in scientific literature?".pdf</a
          >
        </p>
        <div>
          <img
            src="@/assets/Lost in scientific literature.jpg"
            class="small-img"
            alt="Lost in scientific literature"
          />
        </div>

        <h3>Europe PMC logo</h3>
        <p>
          CMYK files should be used when the logo will be printed, be it on
          paper or any other material, while RGB files should be used when the
          logo will be presented on-screen, such as on computers, mobile devices
          or television.
        </p>
        <div class="fig-table">
          <div class="fig-row">
            <div class="fig-cell">
              Europe PMC Basic Logo.png (<a :href="basicLogoRGBLink + '?t=1'"
                >RGB</a
              >, <a :href="basicLogoCMYKLink + '?t=1'">CMYK</a>)
            </div>
            <div class="fig-cell">
              Europe PMC Extended Logo.png (<a
                :href="extendedLogoRGBLink + '?t=1'"
                >RGB</a
              >, <a :href="extendedLogoCMYKLink + '?t=1'">CMYK</a>)
            </div>
          </div>
          <div class="fig-row">
            <div class="fig-cell">
              <img
                src="@/assets/Europe PMC Basic Logo RGB.png"
                class="smaller-img"
                alt="Europe PMC Basic Logo RGB"
              />
            </div>
            <div class="fig-cell">
              <img
                src="@/assets/Europe PMC Extended Logo RGB.png"
                class="small-img"
                alt="Europe PMC Extended Logo RGB"
              />
            </div>
          </div>
        </div>

        <h3>Europe PMC Logo Usage Guidelines</h3>
        <p>
          Please use only the official files. Do not change the Europe PMC logo.
          Do not stretch the image. The provided logo is designed to be used on
          light backgrounds. There should be sufficient clear space around the
          Europe PMC logo to ensure visibility. Please allow a margin of around
          one third of its size.
        </p>
        <div>
          <img
            src="@/assets/Europe PMC logo guidelines.png"
            class="smaller-img"
            alt="Europe PMC logo guidelines"
          />
        </div>

        <h3>Europe PMC funders logo</h3>
        <p>
          You can also download the cumulative logos of all Europe PMC funders.
        </p>
        <p>
          <a :href="fundersLogoLink + '?t=1'">Europe PMC Funders Logo.png</a>
          (526KB)
        </p>
        <div>
          <img
            src="@/assets/Europe PMC Funders logo.png"
            class="med-img"
            alt="Europe PMC Funders logo"
          />
        </div>

        <h2>Training</h2>
        <h3>Using Europe PMC as a Funder</h3>
        <p>
          Webinar slides:
          <a href="//figshare.com/s/28803033b1e988e3964f"
            >https://figshare.com/s/28803033b1e988e3964f</a
          >
        </p>
        <p>
          Recording of webinar:
          <a href="//youtu.be/SMSUMbtAYGM">https://youtu.be/SMSUMbtAYGM</a>
        </p>

        <h3>
          Learn how to use Europe PMC for effective and comprehensive literature
          research.
        </h3>
        <p>
          <a
            href="//www.ebi.ac.uk/training/online/course/europe-pmc-quick-tour-0"
            >Europe PMC: Quick tour</a
          >
        </p>
        <p>
          Gives a brief introduction to Europe PMC for biomedical and life
          sciences researchers.
        </p>

        <p>
          <a
            href="//www.ebi.ac.uk/training/online/course/europe-pmc-get-most-literature-searches"
            >Europe PMC: get the most from literature searches</a
          >
        </p>
        <p>
          Covers searches by topic and author, advanced search options for
          building complex queries, and setting alerts for your topic to stay on
          top of the published literature.
        </p>

        <p>
          <a
            href="//www.ebi.ac.uk/training/online/course/navigating-data-rich-literature-finding-evidence-biomedical-publications"
            >Navigating the data-rich literature: finding evidence in biomedical
            publications</a
          >
        </p>
        <p>
          Demonstrates how to locate primary data in a publication, find facts
          and evidence to support your hypothesis, and scan a paper for
          important biological concepts.
        </p>

        <p>
          <a
            href="//www.ebi.ac.uk/training/online/course/embl-ebi-programmatically-take-rest-manual-searches/europe-pmc-programmatically/extracting"
            >Extracting research evidence from publications</a
          >
        </p>
        <p>
          Demonstrates how to access text-mined literature evidence using Europe
          PMC Annotations API.
        </p>

        <p>
          <a
            href="//www.ebi.ac.uk/training/online/course/embl-ebi-programmatically-take-rest-manual-searches/europe-pmc-programmatically"
            >Europe PMC, programmatically</a
          >
        </p>
        <p>
          Demonstrates how to access publications and related information, such
          as citations, data links, or text-mined terms, programmatically using
          the Europe PMC Articles RESTful API.
        </p>

        <div id="license">
          <a rel="license" href="//creativecommons.org/licenses/by-sa/4.0/"
            ><img
              alt="Creative Commons Licence"
              src="https://i.creativecommons.org/l/by-sa/4.0/88x31.png" /></a
          ><br />This work is licensed under a
          <a rel="license" href="//creativecommons.org/licenses/by-sa/4.0/"
            >Creative Commons Attribution-ShareAlike 4.0 International
            License</a
          >.
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#outreach-page {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
  .med-img {
    width: $base-unit * 128;
  }
  .small-img {
    width: $base-unit * 64;
  }
  .smaller-img {
    width: $base-unit * 40;
  }
  .fig-table {
    display: table;
    .fig-row {
      display: table-row;
      .fig-cell {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-right: $base-unit * 8;
      }
    }
  }
  #license {
    margin-top: $base-unit * 8;
  }
}
</style>
